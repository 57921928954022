.progress {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__test {
		flex-basis: 1;
		border: solid 3px #fff;

		border-radius: 50%;
		width: 25px;
		height: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
	}

	&__line {
		border-bottom: solid 3px #fff;
		flex: 1;
		height: 1px;
		margin: 0px 7px;
	}
	.completed {
		color: lime;
		border-color: lime;
	}
	.current {
		color: #ffff46;
		border-color: #ffff46;
	}
}
