.PhonemeDeletion {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	.AudioButton {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}
}
.lit-stop-watch-div {
	display: none;
}
.lit-count-down-div {
	margin: -22px 0px 0px 0px;
	position: relative;
	top: 18px;
}

.lit-correct-button-div {
	margin: 0px !important;
}
.lit-all-buttons-div {
	display: flex;
}
