@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');
body {
	color: white;
	font-size: 25px;
	background-color: #46b6e5;
	background: linear-gradient(to bottom right, #163042, #0f4475);
	background-attachment: fixed;
	font-family: 'Lato', sans-serif;
	min-height: 100vh;
	padding: 0;
	margin: 0;
}

button {
	font-size: 20px;
	border-radius: 5px;
	background-color: #46b6e5;
	color: white;
	border: none;
	padding: 10px 20px;
}

button:not(:first-child) {
	margin-left: 10px;
}

button:disabled {
	background-color: #ccc;
	color: #555;
}

input {
	font-size: 20px;
	padding: 10px;
}

#root {
	min-height: 100vh;
	flex: 1;
}
