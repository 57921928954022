.Spoonerism {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	.AudioButton {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}

	.ReadyButton {
		margin-top: 15px;
	}

	.spoonerism-examples {
		.example {
			display: flex;
			justify-content: center;
			align-items: center;

			.example-box {
				padding: 10px;
				background-color: white;
				border-radius: 5px;
				color: black;
				margin: 5px 10px;
			}

			.arrow {
				width: 50px;
			}
		}
	}
}
.lit-stop-watch-div {
	display: none;
}
.lit-count-down-div {
	margin: -22px 0px 0px 0px;
	position: relative;
	top: 18px;
}

.lit-correct-button-div {
	margin: 0px !important;
}
.lit-all-buttons-div {
	display: flex;
}