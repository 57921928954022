.RapidNamingIntro {
	display: flex;
	flex-direction: column;
	align-items: center;

	.items {
		display: flex;
		padding: 40px;
		background-color: white;
		margin-bottom: 20px;
		border-radius: 10px;
		.image {
			width: 100px;
			height: 100px;
			margin: 20px;
		}
		.circle {
			width: 100px;
			height: 100px;
			border-radius: 50%;
			margin: 20px;

			&.red {
				background-color: #ff0000;
			}

			&.green {
				background-color: #00ff00;
			}

			&.blue {
				background-color: #0000ff;
			}
			&.orange {
				background-color: #ff8a00;
			}
			&.yellow {
				background-color: #ffff00;
			}
			&.brown {
				background-color: #9b5d01;
			}
			&.gray {
				background-color: #888888;
			}
		}
	}
}

.ScrollingTest {
	display: flex;
	flex-direction: column;
	align-items: center;

	.row {
		display: flex;
		background-color: white;
		margin: 10px;
		padding: 0 20px;
		border-radius: 10px;

		&.previous,
		&.next {
			background-color: transparent;
			font-size: 12px;
			padding: 0px;

			.item {
				color: #999;
				img {
					width: 100px;
					opacity: 0.3;
				}
			}
			@media screen and (max-width: 1440px) {
				.item {
					img {
						width: 90px;
					}
					padding: 10px 10px;
				}
			}
			@media screen and (max-width: 1100px) {
				.item {
					img {
						width: 70px;
					}
					padding: 10px 15px;
				}
			}
			@media screen and (max-width: 800px) {
				.item {
					img {
						width: 70px;
					}
					padding: 10px 5px;
				}
			}
		}

		.item {
			display: flex;
			flex-direction: column;
			line-height: 1;
			padding: 10px;
			font-size: 14px;
			margin: 0;
			color: #999;
			&.active {
				// background-color: #1b2e79;
			}

			img {
				width: 150px;
			}
		}
		@media screen and (max-width: 1440px) {
			.item {
				img {
					width: 125px;
				}
				padding: 10px 0px;
			}
		}
		@media screen and (max-width: 1100px) {
			.item {
				img {
					width: 90px;
				}
				padding: 10px 0px;
			}
		}
		@media screen and (max-width: 800px) {
			.item {
				img {
					width: 80px;
				}
				padding: 10px 0px;
			}
			padding: 0 10px;
		}
	}
}
